import { LeadWebsite } from '../../../__generated__/api-types'

export const brandName = 'Rent.'

export const brandSeoDisplayName = brandName

export const brandTrackingName = 'rent'

export const brandHostUrl = 'https://www.rent.com'

export const brandAppId = 'rent/www'

export const brand: 'ag' | 'rent' = 'rent'

/**
 * This value is used for lead submissions.
 *
 * @param isMobile
 */
export function getBrandWebsite(isMobile: boolean) {
  return isMobile ? LeadWebsite.MRent : LeadWebsite.Rent
}
